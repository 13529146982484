import {
	FieldDefInit,
	EditDialog,
	Field,
	FlexC,
	Flex,
	Text,
	ICustomDialogCallbackProps,
	ItemSelector,
	ComboBox,
	ComboBoxProvider,
	ComplexComboBox,
	useModal,
	Cancel,
	ButtonType,
	Save
} from '@app/muiplus'
import { useCallback, useEffect, useState } from 'react'
import anylogger from '@app/anylogger'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { adjustDate, formatDateTime, toLocaleISOFormat } from '@app/utils'
import { GoogleEvent } from '@app/googlecalendar'
import { ICloudObject, ICloudStorage } from '@app/cloudstorage'
import { ClientProxy } from '@app/clientserverutils'
import { IMdxSource } from '@app/mdxsource'

const log = anylogger('CalendarEditDialog')
export const UpdateAllEvents = 1

export function CalendarEditDialog({ event, dataChanged, setHooks }: CalendareEditDialogProps) {
	if (!dataChanged) throw new Error(`You must specify dataChanged`)
	const [allDay, setAllDay] = useState(event.start.date ? true : false)
	const { customDialog } = useModal()

	const onFinish = useCallback(
		async (buttonId: ButtonType, data: GoogleEvent) => {
			if (buttonId != Save) return

			if (data.recurringEventId) {
				const res = await customDialog({
					title: 'This is a recurring event',
					dialog: <>'Do you want to change just this event or all events?'</>,
					buttons: [
						{ id: Save, caption: 'This Event' },
						{ id: UpdateAllEvents, caption: 'All Events' },
						{ id: Cancel, caption: 'Cancel' }
					]
				})
				if (res.result == Cancel) return { preventClose: true }
				if (res.result == UpdateAllEvents) {
					// return { data: { ...data, id: data.recurringEventId, recurringEventId: undefined } }
					return { result: UpdateAllEvents }
				}
			}
			return
		},
		[customDialog]
	)

	useEffect(() => {
		if (setHooks) setHooks(onFinish)
	}, [onFinish, setHooks])

	const fields: FieldDefInit[] = [
		['Title', 'summary', 'string'],
		['Description', 'description', ['multiline', { context: { minRows: 5, maxRows: 10 } }]]
		// ['Description', 'description', 'string'],
		// ['Date Taken', 'dateTaken', ['date', { disabled: isTrue(meta.dtRO) }]],
		// ['Date RO', 'dtRO', 'boolean'],
		// ['Author', 'author', ['string', { disabled: true }]],
	]

	return (
		<EditDialog<any> data={event} fields={[]} dataChanged={dataChanged}>
			<Field fieldDef={fields[0]} />
			<FlexC>
				<StartTime event={event} dataChanged={dataChanged} onAllDayChanged={setAllDay} />
				<EndTime event={event} dataChanged={dataChanged} allDay={allDay} />
			</FlexC>
			<CalendarPageLinkField event={event} dataChanged={dataChanged} />
			<Field fieldDef={fields[1]} />
		</EditDialog>
	)
}

export interface CalendareEditDialogProps extends ICustomDialogCallbackProps {
	event: GoogleEvent
}
interface CalendarEventFieldProps {
	event: GoogleEvent
	dataChanged: (data: any) => void
}
interface StartTimeProps extends CalendarEventFieldProps {
	onAllDayChanged: (allDay: boolean) => void
}
export function StartTime(props: StartTimeProps) {
	const { event, dataChanged, onAllDayChanged } = props
	const [allDay, setAllDay] = useState(event.start.date ? true : false)
	const [date, setDate] = useState(
		event.start.date || (event.start.dateTime && formatDateTime(new Date(event.start.dateTime), 'yyyy-mm-dd'))
	)
	const [time, setTime] = useState<string>(() => {
		if (!event.start.dateTime) return '00:00'
		const dt = new Date(event.start.dateTime)
		return formatDateTime(dt, 'hh:nn')
	})

	useEffect(() => {
		if (allDay) {
			event.start.date = date
			delete event.start.dateTime
		} else {
			delete event.start.date
			event.start.dateTime = toLocaleISOFormat(date!, time)
		}

		if (dataChanged) dataChanged(event)
	}, [allDay, dataChanged, date, event, event.start, time])
	const allDayChanged = useCallback(
		(e: any) => {
			setAllDay(e.target.checked)
			onAllDayChanged(e.target.checked)
		},
		[onAllDayChanged]
	)
	const dateChanged = useCallback((e: any) => {
		setDate(e.target.value)
	}, [])
	const timeChanged = useCallback((e: any) => {
		setTime(e.target.value)
	}, [])
	return (
		<Flex alignItems="center">
			<TextField
				type="date"
				value={date}
				onChange={dateChanged}
				sx={{
					width: '8em'
				}}
			/>
			<Text>All Day</Text>
			<Checkbox checked={allDay} onChange={allDayChanged} />
			{!allDay && (
				<TextField
					type="time"
					value={time}
					onChange={timeChanged}
					sx={{
						width: '8em'
					}}
				/>
			)}
		</Flex>
	)
}
interface EndTimeProps extends CalendarEventFieldProps {
	allDay: boolean
}
export function EndTime(props: EndTimeProps) {
	const { event, dataChanged, allDay } = props
	const [date, setDate] = useState(() => {
		if (event.end.date) {
			const dt = formatDateTime(adjustDate(event.end.date, -1), 'yyyy-mm-dd')
			return dt
		} else return event.end.dateTime && formatDateTime(new Date(event.end.dateTime), 'yyyy-mm-dd')
	})
	const [time, setTime] = useState<string>(() => {
		if (!event.end.dateTime) return '00:00'
		const dt = new Date(event.end.dateTime)
		return formatDateTime(dt, 'hh:nn')
	})

	useEffect(() => {
		if (allDay) {
			event.end.date = formatDateTime(adjustDate(date!, 1), 'yyyy-mm-dd')
			delete event.end.dateTime
		} else {
			delete event.end.date
			event.end.dateTime = toLocaleISOFormat(date!, time)
		}
		if (dataChanged) dataChanged(event)
	}, [allDay, dataChanged, date, event, event.end, event.start, time])
	const dateChanged = useCallback((e: any) => {
		setDate(e.target.value)
	}, [])
	const timeChanged = useCallback((e: any) => {
		setTime(e.target.value)
	}, [])
	return (
		<Flex alignItems="center">
			<TextField
				type="date"
				value={date}
				onChange={dateChanged}
				sx={{
					width: '8em'
				}}
			/>
			{!allDay && (
				<TextField
					type="time"
					value={time}
					onChange={timeChanged}
					sx={{
						width: '8em'
					}}
				/>
			)}
		</Flex>
	)
}
export const trimNumberPrefix = (str: string): string => {
	const matches = str.match(/[0-9]*\-(.*)/)
	const res = matches ? matches[1] : str
	return res
}
export const trimFileExtension = (str: string): string => {
	if (str.toLocaleLowerCase().endsWith('.mdx')) str = str.substring(0, str.length - 4)
	return str
}

interface CalendarPageLinkFieldProps extends CalendarEventFieldProps {}
export function CalendarPageLinkField(props: CalendarPageLinkFieldProps) {
	const { event, dataChanged } = props
	// const [pages, setPages] = useState<string[]>([])
	const [api] = useState<IMdxSource>(new ClientProxy(new IMdxSource(), '/api/mdxContent') as IMdxSource)
	const [items, setItems] = useState<string[]>([])
	const [value, setValue] = useState<string | undefined>('')

	const onChange = useCallback(
		(e: any, value: any) => {
			setValue(value)
			event.location = value || ''
			if (dataChanged) dataChanged(event)
		},
		[dataChanged, event]
	)
	useEffect(() => {
		const res = api.GetPaths().then((res) => {
			const items = res.map((strs) => strs.join('/'))
			// const items = res.filter((item) => item.name.toLocaleLowerCase().endsWith('.mdx') && item.name !== 'index.mdx')
			items.push('')
			setItems(items)
			const item = items.find((item) => item === event.location)
			if (item) setValue(item)
		})
	}, [api, event.location])

	return (
		<Autocomplete
			value={value}
			onChange={onChange}
			autoHighlight
			options={items}
			// getOptionLabel={(option) => {
			// 	if (!option?.path) {
			// 		log('option', option)

			// 		return ''
			// 	}
			// 	return trimFileExtension(trimNumberPrefix(option.path))
			// }}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Optionally link to a web page"
					// slotProps={{
					// 	htmlInput: {
					// 		...params.inputProps
					// 		// autoComplete: 'new-password' // disable autocomplete and autofill
					// 	}
					// }}
				/>
			)}
			sx={{
				flexGrow: 1,
				width: '600px'
			}}
		/>
	)
}
