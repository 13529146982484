import anylogger from '@app/anylogger'

// This attempts to mimic the pertinent fields of the Person object of the Google People
export interface GoogleCalendar {
	id: string
	primary: boolean
}
export type GoogleCalendarList = GoogleCalendar[]
export interface ListEventParams {
	calendarId?: string
	timeMin?: string
	timeMax?: string
}
export interface UpdateEventParams {
	calendarId?: string
}
export interface GetEventParams {
	calendarId?: string
}
interface GoogleEventDateTime {
	date?: string
	dateTime?: string
	timeZone?: string
}
interface GoogleEventReminderInstance {
	method: 'email' | 'popup'
	minutes: number
}
interface GoogleEventReminder {
	useDefault?: boolean
	overrides: GoogleEventReminderInstance[]
}
interface GoogleEventAttachment {
	fileUrl?: string
	title: string
	mimeType: string
	iconLink?: string
	fileId?: string
}
export interface GoogleEvent {
	id: string
	calendarId: string
	start: GoogleEventDateTime
	end: GoogleEventDateTime
	originalStartTime?: GoogleEventDateTime
	summary: string
	description: string
	recurrence: string[]
	reminders: GoogleEventReminder
	attachments?: GoogleEventAttachment[]
	location: string
	recurringEventId?: string
}
export type GoogleEventList = GoogleEvent[]

export class IGoogleCalendar {
	listCalendars!: () => Promise<GoogleCalendarList>
	listEvents!: (params: ListEventParams) => Promise<GoogleEventList>
	getEventById!: (id: string, params?: GetEventParams) => Promise<GoogleEvent>
	updateEvent!: (event: GoogleEvent, params?: UpdateEventParams) => Promise<void>
	updateAllRecurringEvents!: (event: GoogleEvent, params?: UpdateEventParams) => Promise<void>
}
