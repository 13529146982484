import anylogger from '@app/anylogger'
import { ClientProxy } from '@app/clientserverutils'
import { getMdxSource } from '@app/easymdx'
import { IMdxSource } from '@app/mdxsource'
import { Centered, H5, MenuContext } from '@app/muiplus'
import { Error404 } from '@app/uiutils'
import { WebMenu } from '@app/utils'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import CalendarController from 'components/Calendar'
import { GeneralLayout } from 'components/GeneralLayout'
import { MdxView } from 'components/MdxView'
import { GetStaticProps } from 'next'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { filterMemberMenu } from 'src/services/filterMemberMenu'
import { getMemberMenu } from 'src/services/getMemberMenu'
import { useCombinedMenu } from 'src/services/useCombinedMenu'
import { useSession } from 'src/services/useSession'
import { WebEditorService } from 'src/services/WebEditor/WebEditorService'

export const log = anylogger('[...paths]')

// log('config', config)

export async function getStaticPaths() {
	const src = await getMdxSource('getStaticpaths')
	const paths = await src.GetPaths()
	// log.info('getStatic Paths:', paths.join(', '))

	const paramPaths = paths.map((path) => {
		// each path is an array of directory entries for the file
		// i.e. ['About', 'Contact Us']
		// the paramPaths is an array of these path entries, which will create a "page" for each path in paramPaths
		return {
			params: {
				paths: path
			}
		}
	})
	paramPaths.push({ params: { paths: ['Calendar'] } })

	// We'll pre-render only these paths at build time.
	// { fallback: false } means other routes should 404.
	// { fallback: true } means all other url paths will be passed to getStaticProps to try to rosolve them
	// { fallback: blocking} means that it will run getStaticProps bofore returning a result, making the response slower, but preventing a "Not Found" page, while the
	// page is being calculated.
	return { paths: paramPaths, fallback: 'blocking' }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
	let menu: WebMenu = []
	let memberMenuItems: WebMenu = []
	const createResponse = (props: any) => {
		return { props: { ...props, menu, memberMenuItems } }
	}

	try {
		// log.info('[path] getStaticProps:', params)

		let paths: string[]
		if (!params?.paths) paths = ['Home']
		else paths = params?.paths as string[]

		let src: IMdxSource
		src = await getMdxSource('[paths]')
		if (process.env.NEXT_PHASE != PHASE_PRODUCTION_BUILD) {
			await src.RefreshContent()
		}

		menu = await src.GetMenu()
		menu.splice(1, 0, { caption: 'Calendar', url: '/Calendar' })

		memberMenuItems = getMemberMenu()

		const entry = src.FindEntry(paths)

		if (!entry) return createResponse({ notFound: true })

		const rawContent = await src.GetContent(entry)

		let meta: any = { fullPath: entry.fullPath }
		let source: any = ''
		let scope: any = { ...meta }

		const useFineApi = true

		if (useFineApi) {
			if (!rawContent) return createResponse({ notFound: true })
			let parsed = await src.ParseContent(rawContent)
			let { content } = parsed
			meta = { ...meta, ...parsed.meta }

			content = src.ApplyPrependedContent(content, meta)

			const childData = await src.GetChildData(entry)
			scope = { ...scope, childData }
			// log('childData', childData)

			const pageData = await src.GetPageData(meta.pageData)
			if (Array.isArray(pageData)) scope.pageData = pageData
			// log('pageData', pageData)
			if (meta.stringData) scope.stringData = meta.stringData

			if (meta.summaryImage) scope.summaryImage = meta.summaryImage

			source = await src.GetSerializedContent(content, scope)
		} else {
			// const { meta, content } = await src.GetParsedContent(entry)
			// scope can be childData, referencedPages, frontMatter objects
			// const { meta, content, serialized, scope } = src.ProcessContent(rawContent, { applyPrepends: true })
		}

		return createResponse({ source, meta })
	} catch (err) {
		log.error(err)
		return createResponse({ error: 'Exception: ' + err })
	}
}

const MDXContent = React.forwardRef(function MDXContent(props: any, ref: any) {
	let { notFound, error, meta, source, menu: webMenu = [], memberMenuItems = [] } = props
	const router = useRouter()
	const [memberMenu, setMemberMenu] = useState<WebMenu>([])

	const [session, loading] = useSession()
	useEffect(() => {
		if (!session) return
		setMemberMenu(filterMemberMenu(session.roles, memberMenuItems))
	}, [memberMenuItems, session, session?.roles])

	const menu = useCombinedMenu(session, webMenu, memberMenu)

	let content
	if (error) {
		log('error', error)

		content = (
			<Centered>
				<H5>{error}</H5>
			</Centered>
		)
	} else if (router.asPath == '/Calendar') {
		content = <CalendarController></CalendarController>
	} else if (!source || notFound) {
		content = <Error404 />
	} else content = <MdxView source={source} data={meta} />

	const menuContext = { items: menu }
	return (
		<MenuContext.Provider value={menuContext}>
			<GeneralLayout>{content}</GeneralLayout>
		</MenuContext.Provider>
	)
})
export default MDXContent
