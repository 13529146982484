import { IMenuList } from '@app/muiplus'
import { WebMenu, WebMenuItem } from '@app/utils'
import { useEffect, useState } from 'react'
import { MdxMenu } from 'src/lib/app/MdxSource/src/MdxMenu'

export function useCombinedMenu(session: any, webMenu: MdxMenu, memberMenu: WebMenu): IMenuList {
	const [menu, setMenu] = useState<IMenuList>([])

	useEffect(() => {
		const mapMenu = (menu: MdxMenu | undefined): IMenuList => {
			if (!menu) return []
			return menu.map((m) => {
				return { caption: m.caption, target: m.url ?? '', children: mapMenu(m.children), options: { isEditable: m?.isEditable } }
			})
		}
		let m: WebMenu = []
		if (webMenu) m.push(...webMenu)
		if (memberMenu && session) m.splice(2, 0, { caption: 'Members', url: '/Members/Home', children: [...memberMenu] })
		const items = mapMenu(m)
		setMenu(items)
	}, [session, webMenu, memberMenu])

	return menu
}
